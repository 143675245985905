var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"px-0 pb-4 m-0",attrs:{"fluid":""}},[_c('section',{staticClass:"d-md-none"},[_c('h3',{staticClass:"mb-3 page-title"},[_vm._v("Profilo Sanitario")]),_c('b-button-group',{staticClass:"general_border mb-4"},[_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'rilevazioni'
            ? 'secondary'
            : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'profilo sanitario',
            params: { section: 'rilevazioni' }
          })}}},[_c('span',[_vm._v("Rilevazioni")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'patologie'
            ? 'secondary'
            : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'profilo sanitario',
            params: { section: 'patologie' }
          })}}},[_c('span',[_vm._v("Patologie")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'terapie'
            ? 'secondary'
            : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'profilo sanitario',
            params: { section: 'terapie' }
          })}}},[_c('span',[_vm._v("Terapie")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'note' ? 'secondary' : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'profilo sanitario',
            params: { section: 'note' }
          })}}},[_c('span',[_vm._v("Note")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'logs' ? 'secondary' : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'profilo sanitario',
            params: { section: 'logs' }
          })}}},[_c('span',[_vm._v("Logs")])])],1)],1),_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(_vm.$route.params.section === 'rilevazioni')?_c('b-row',{key:"rilevazioni"},[_c('RilevazioniComponent',{attrs:{"subject":_vm.subject}})],1):(_vm.$route.params.section === 'patologie')?_c('b-row',{key:"patologie"},[_c('PatologieComponent',{attrs:{"subject":_vm.subject}}),_c('EsenzioniComponent',{attrs:{"subject":_vm.subject}})],1):(_vm.$route.params.section === 'terapie' && !_vm.$route.params.action)?_c('b-row',{key:"terapie"},[_c('ArmadiettoComponent',{attrs:{"subject":_vm.subject}})],1):(_vm.$route.params.section === 'note' && !_vm.$route.params.action)?_c('b-row',{key:"annotations"},[_c('PatientAnnotations',{attrs:{"hidetop":false}})],1):(_vm.$route.params.section === 'logs' && !_vm.$route.params.action)?_c('b-row',{key:"accesslog"},[_c('AccessLogs')],1):(
        _vm.$route.params.section === 'terapie' &&
        _vm.$route.params.action === 'nuova'
      )?_c('b-row',{key:"nuovaterapia"},[_c('NuovaTerapia')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }