<template>
  <b-container fluid class="px-0 pb-4 m-0">
    <section class="d-md-none">
      <h3 class="mb-3 page-title">Profilo Sanitario</h3>
      <b-button-group class="general_border mb-4">
        <b-button
          :variant="
            $route.params.section === 'rilevazioni'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'profilo sanitario',
              params: { section: 'rilevazioni' }
            })
          "
        >
          <span>Rilevazioni</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'patologie'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'profilo sanitario',
              params: { section: 'patologie' }
            })
          "
        >
          <span>Patologie</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'terapie'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'profilo sanitario',
              params: { section: 'terapie' }
            })
          "
        >
          <span>Terapie</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'note' ? 'secondary' : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'profilo sanitario',
              params: { section: 'note' }
            })
          "
        >
          <span>Note</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'logs' ? 'secondary' : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'profilo sanitario',
              params: { section: 'logs' }
            })
          "
        >
          <span>Logs</span>
        </b-button>
      </b-button-group>
    </section>
    <transition-group name="list" mode="out-in" appear>
      <b-row v-if="$route.params.section === 'rilevazioni'" key="rilevazioni">
        <RilevazioniComponent :subject="subject" />
        <!-- <ScoresComponent :subject="{hashid:user.hashid}" :aspatient="true"/> -->
      </b-row>
      <b-row v-else-if="$route.params.section === 'patologie'" key="patologie">
        <PatologieComponent :subject="subject" />
        <EsenzioniComponent :subject="subject" />
      </b-row>
      <b-row
        v-else-if="$route.params.section === 'terapie' && !$route.params.action"
        key="terapie"
      >
        <ArmadiettoComponent :subject="subject" />
      </b-row>
      <b-row
        v-else-if="$route.params.section === 'note' && !$route.params.action"
        key="annotations"
      >
        <PatientAnnotations :hidetop="false" />
      </b-row>
      <b-row
        v-else-if="$route.params.section === 'logs' && !$route.params.action"
        key="accesslog"
      >
        <AccessLogs />
      </b-row>
      <b-row
        v-else-if="
          $route.params.section === 'terapie' &&
          $route.params.action === 'nuova'
        "
        key="nuovaterapia"
      >
        <NuovaTerapia />
      </b-row>
    </transition-group>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex"
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue"
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue"
import ArmadiettoComponent from "@/components/profile/sanitario/ArmadiettoComponent.vue"
import NuovaTerapia from "@/components/cittadino/NuovaTerapia.vue"
import PatientAnnotations from "@/components/profile/sanitario/PatientAnnotations.vue"
import AccessLogs from "@/components/profile/sanitario/AccessLogs.vue"
import EsenzioniComponent from "@/components/profile/sanitario/EsenzioniComponent.vue";

// import ScoresComponent from "@/components/profile/sanitario/ScoresComponent.vue";
export default {
  name: "ProfiloSanitarioMain",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    ArmadiettoComponent,
    NuovaTerapia,
    PatientAnnotations,
    AccessLogs,
    EsenzioniComponent,
  },
  data() {
    return {
      activePanel: "rilevazioni",
      subject: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["me"]),
    fetchUser() {
      // get user by id, then subjectId = user fetched hashid
      // subject:{
      //   hashid:user.hashid,
      //   type:"familiare"
      // }
    }
  },
  created() {
    if (this.$route.params.subject) {
      this.fetchUser()
    }
  }
}
</script>
