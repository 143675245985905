<template>
  <div class="col-12 m-0 p-0 px-2 px-md-4">
    <div class="d-flex justify-content-between mb-2 pb-2">
      <h4 class="text-left page-lg-title">Accessi al tuo profilo sanitario</h4>

      <b-col class="text-right pr-0">
        <span class="mr-2">
          <label for="pickedMonth" class="mr-1">mese</label>
          <select
            class="form-select bg-white border border-info general_border"
            v-model="filters.month"
            @change="fetchData"
            name="pickedMonth"
            id="pickedMonth"
          >
            >
            <option
              v-for="month in historyFilterOptions.month"
              :value="month.value"
            >
              {{ month.label }}
            </option>
            <!-- <option :value="null">Tutti</option> -->
          </select>
        </span>
        <span>
          <label for="pickedYear" class="mr-1">Anno</label>

          <select
            class="form-select bg-white border border-info general_border"
            v-model="filters.year"
            @change="fetchData"
            name="pickedYear"
            id="pickedYear"
          >
            <option
              v-for="year in yearsOptions"
              :value="year.value"
              :key="year.value"
            >
              {{ year.text }}
            </option>
          </select>
        </span>
      </b-col>
    </div>

    <template v-if="!fetching">
      <template v-if="accessLogs">
        <template v-for="month in enabledMonths">
          <div
            class="my-4 pb-1"
            v-if="
              filters.month == null ||
              (filters.month !== null && month == filters.month)
            "
            :key="month"
          >
            <h3 class="text-capitalize text-left my-4">
              {{ mappedMonths[month] }}
            </h3>
            <template v-if="accessLogs[month] && accessLogs[month].length > 0">
              <template v-for="(log, ind) in accessLogs[month]">
                <b-card
                  no-body
                  border-variant="white"
                  class="shadow_2 bg-white general_border mb-3"
                >
                  <b-row class="p-3 justify-content-between" align-v="center">
                    <b-col class="col-12 pl-0 text-left fs-6">
                      <span class="fs-6 pr-2">
                        {{ formatMonth(log.access_at) }}</span
                      >
                    </b-col>
                    <span class="text-left">
                      <b-avatar
                        :src="log.company.profile_pic"
                        size="1.7rem"
                        :text="log.operator_initials"
                        variant="primary"
                        rounded
                      >
                      </b-avatar>
                      <span class="text-capitalize">
                        {{ log.operator_fullname }},</span
                      >
                      <span class="text-capitalize">
                        {{ log.company.denominazione }} ({{
                          log.company.comune
                        }})
                      </span>
                    </span>
                    <b-col class="text-right"> </b-col>
                  </b-row>
                </b-card>
              </template>
            </template>
            <p v-else class="text-left">Nessun accesso per questo mese.</p>
          </div>
        </template>
      </template>
      <template v-else>
        <p class="text-center pt-4 mt-4">
          Nessun accesso nell'anno selezionato.
        </p>
      </template>
    </template>
    <b-spinner v-else></b-spinner>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
import { MONTHS } from "@/_utils/constants.js";

export default {
  name: "AccessLogs",
  data() {
    return {
      fetchCount: 0,
      yearsOptions: [],
      accessLogs: null,
      // pickedYear: undefined,
      alreadyFetched: null,
      filters: {
        year: moment().year(),
        month: moment().month() + 1,
      },
      notes: null,
      mappedMonths: {
        "01": "gennaio",
        "02": "febbraio",
        "03": "marzo",
        "04": "aprile",
        "05": "maggio",
        "06": "giugno",
        "07": "luglio",
        "08": "agosto",
        "09": "settembre",
        10: "ottobre",
        11: "novembre",
        12: "dicembre",
      },
      enabledMonths: null,
      historyFilterOptions: {
        year: null,
        month: MONTHS,
      },
      fetching: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.fetching = true;
      this.setupMonths();
      const self = this;
      supportService
        .getAccessOnPatientChart(this.filters)
        .then(function (response) {
          self.accessLogs = response.data.data;
          self.fetching = false;
          // console.log(response.data.data)
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le note");
          self.fetching = false;
        });
    },
    setupMonths() {
      this.enabledMonths = null;
      let now = moment();
      let currentYear = now.year();
      let enabledMonths = [];
      var indx = now.month() + 1;
      if (this.filters.year != currentYear) {
        now.year(currentYear);
        now.month(11);
        indx = 12;
      }
      for (let x = indx; x >= 1; x--) {
        enabledMonths.push(now.format("MM"));
        now.subtract(1, "months");
      }
      this.enabledMonths = enabledMonths;
    },
    formatMonth(date) {
      return moment(date).format("D MMMM \or\\e HH:mm");
    },
  },
  created() {
    let now = moment();
    let currentYear = now.year();
    let startYear = 2022;
    for (let i = currentYear; i >= startYear; i--) {
      let y = { value: `${i}`, text: `${i}` };
      this.yearsOptions.push(y);
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style>
select[name="pickedYear"] {
  min-width: 70px;
  min-height: 33px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
